"use client";
import { signIn } from "next-auth/react";
import { Button } from "@nextui-org/react";
import { FcGoogle } from "react-icons/fc";

const GoogleLogin: React.FC = () => {
  return (
    <div className="w-full absolute bottom-0 p-5 left-0">
      <Button
        color="secondary"
        className="rounded-md p-3 gap-2 border-primary border h-auto w-full flex items-center justify-center text-darkPrimary"
        onClick={async () => {
          await signIn("google", undefined, {
            redirect_uri: `${window.location.origin}/api/auth/callback/google`,
          });
        }}
      >
        <FcGoogle size={20} />
        <p>Ingresar con Google</p>
      </Button>
    </div>
  );
};

export default GoogleLogin;
